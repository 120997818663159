import React, { Fragment, useCallback, useMemo, useState } from "react";
import NextLink from "next/link";
import {
  Box,
  TableCell,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { format } from "d3-format";
import { addUnderscore, currencify } from "@lib/utils/formatting";
import { getExchangeSlug } from "@lib/utils";
import NumberDynamic from "@components/NumberDynamic";
import SkeletonImg from "@components/SkeletonImg";
import TablePage, {
  StaticPageProps,
  getDefaultGetTitles,
  Title,
  columnsToHide,
} from "@pages-components/TablePage";
import { getOverview } from "@server-lib/exchanges";
import { getTopTrends } from "@server-lib/trends";
import withAppProps from "@server-lib/with-app-props";
import NumberFormat from "@components/NumberFormat";
import { EXCHANGES_SEO } from "@lib/seo";
import { AppHead } from "@components/AppHead";
import { ExchangesOverviewDataType } from "@pages-components/exchange/types";
import { Currency, Interval } from "@lib/types";
import { DEFAULT_INTERVALS, DEFAULT_INTERVALS_ARRAY } from "@lib/const";
import InfoBlock from "@components/InfoBlock";
import { useMediaStyles } from "@lib/hooks/styles";
import { overviewCategories, defaultCategoryId } from "@lib/utils";
import SelectCategory from "@components/SelectCategory";
import trackEvent, { GAEventCategory } from "@lib/ga";

export const titles: Title[] = [
  "#",
  {
    title: "Name",
    sortBy: "exch_exchange_title",
    props: { width: 200, align: "left" },
  },
  {
    title: "Volume",
    sortBy: ({ currency, interval }) => `volume_${interval}_${currency}`,
    props: { width: 160, align: "center" },
  },
  {
    title: "Volume Change",
    sortBy: ({ currency, interval }) => `volume_change_${interval}_${currency}`,
  },
  { title: "No. of Pairs", sortBy: "exch_num_of_pairs" },
  {
    title: "Avg. Trade",
    sortBy: ({ currency, interval }) =>
      `average_transaction_${interval}_${currency}`,
  },
  {
    title: "No. of Trades",
    sortBy: ({ interval }) => `total_trades_${interval}`,
  },
  {
    title: "No. of Trades Change",
    sortBy: ({ interval }) => `trades_${interval}_change`,
  },
  {
    title: "Asks Orderbook Depth 1%",
    sortBy: ({ currency }) => `ask_tot_${currency}`,
  },
  {
    title: "Bids Orderbook Depth 1%",
    sortBy: ({ currency }) => `bid_tot_${currency}`,
  },
  {
    title: "Volume Chart",
    props: { width: 176 },
  },
];

const pages = [
  { name: "Home", href: "/" },
  { name: "Exchanges", href: "/exchanges" },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",

    [theme.breakpoints.down("md")]: {
      ".title-options": {
        marginBottom: theme.spacing(5),
      },
    },
  },
  category: {
    padding: "4px 12px",
    display: "flex",
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.picker.backgroundHover,
    },
    color: theme.palette.picker.text,
  },
  activeCategory: {
    background: `${theme.palette.picker.background} !important`,
    border: `1px solid ${theme.palette.picker.border} !important`,
    padding: "3px 11px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    "&:hover": {
      background: `${theme.palette.picker.backgroundSelectedHover} !important`,
    },
    color: "#fff",
  },
  desktopChart: {
    overflow: "hidden",
    verticalAlign: "bottom",
    img: {
      display: "block",
    },
  },
  desktopChartImgWrap: {
    minHeight: 64,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mobileChart: {
    width: "calc(100% + 32px)",
    height: 76,
    img: {
      display: "block",
    },
    paddingTop: 0,
    marginBottom: 4,
  },
}));

export default function Exchanges(props: StaticPageProps) {
  const mediaClasses = useMediaStyles();
  const classes = useStyles();
  const [interval, setInterval] = useState(DEFAULT_INTERVALS_ARRAY[0]);
  const [activeCategory, setActiveCategory] = useState(defaultCategoryId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const volumeVal = (d, currency: Currency, interval: Interval) => {
    switch (activeCategory) {
      case 0:
        return d[`mixed_volume_${currency.slug}_${interval.slug}`];
      case 1:
        return d[`volume_${interval.slug}_${currency.slug}_spot`];
      case 2:
        return d[`futures_volume_${currency.slug}_${interval.slug}`];
      case 3:
        return d[`perp_volume_${currency.slug}_${interval.slug}`];
    }
  };

  const volumeChangeVal = (d, currency: Currency, interval: Interval) => {
    switch (activeCategory) {
      case 0:
        return d[`mixed_volume_change_${currency.slug}_${interval.slug}`];
      case 1:
        return d[`volume_change_${interval.slug}_${currency.slug}_spot`];
      case 2:
        return d[`futures_volume_${currency.slug}_change_${interval.slug}`];
      case 3:
        return d[`perp_volume_${currency.slug}_change_${interval.slug}`];
    }
  };

  const askTotVal = (d, currency: Currency) => {
    switch (activeCategory) {
      case 0:
        return d[`ask_100_${currency.slug}_all`];
      case 1:
        return d[`ask_tot_${currency.slug}_spot`];
      case 2:
        return d[`ask_100_${currency.slug}_futures`];
      case 3:
        return d[`ask_100_${currency.slug}_perpetual`];
    }
  };

  const bidTotVal = (d, currency: Currency) => {
    switch (activeCategory) {
      case 0:
        return d[`bid_100_${currency.slug}_all`];
      case 1:
        return d[`bid_tot_${currency.slug}_spot`];
      case 2:
        return d[`bid_100_${currency.slug}_futures`];
      case 3:
        return d[`bid_100_${currency.slug}_perpetual`];
    }
  };

  const numOfPairsVal = (d) => {
    switch (activeCategory) {
      case 0:
        return d[`num_pairs_all`];
      case 1:
        return d[`num_pairs_spot`];
      case 2:
        return d[`num_pairs_futures`];
      case 3:
        return d[`num_pairs_perpetual`];
    }
  };

  const tradeChangeVal = (d: ExchangesOverviewDataType, interval: Interval) => {
    switch (interval.slug) {
      case DEFAULT_INTERVALS.DAY.slug:
        return d.trades_1d_change;
      case DEFAULT_INTERVALS.MONTH.slug:
        return d.trades_1m_change;
      case DEFAULT_INTERVALS.WEEK.slug:
        return d.trades_1w_change;
    }
  };

  const getTitles = useMemo(() => {
    const hideColumn = activeCategory > 1 ? ["Volume Chart"] : [];
    return getDefaultGetTitles(columnsToHide(titles, hideColumn));
  }, []);

  const handleChangeCategory = useCallback((index) => {
    setActiveCategory(index);
  }, []);

  const selectCategory = () => {
    return (
      <Box display="flex" mb={2}>
        <SelectCategory
          categories={overviewCategories}
          activeCategory={activeCategory}
          onChangeCategory={handleChangeCategory}
        />
      </Box>
    );
  };

  return (
    <>
      <AppHead
        title={EXCHANGES_SEO.title}
        description={EXCHANGES_SEO.description}>
        <title>{EXCHANGES_SEO.title}</title>
        <meta name="description" content={EXCHANGES_SEO.description} />
      </AppHead>
      <Box className={classes.wrapper}>
        <TablePage
          {...props}
          pages={pages}
          onIntervalChange={(interval) => {
            setInterval(interval);
          }}
          subChildrensAboveTable={selectCategory}
          filterBy={
            activeCategory === 2
              ? `futures_volume_usd_${interval.slug}`
              : activeCategory === 3
              ? `perp_volume_usd_${interval.slug}`
              : ""
          }
          pageTitle="Crypto Exchanges Overview"
          pageSubtitle="Search our cryptocrrency exchanges ranked by volume, liquidity, orderbook depth, no. of trades, and avg. trade, Binance, Coinbase Pro, Huobi, Kraken Bitfinex and many more, to help you chose the best exchange to fit your needs"
          api="/api/exchanges/overview-list"
          getTitles={getTitles}
          rowKey="exch_exchange_title"
          useIntervals
          rowContent={(rowData, { index, currency, interval }) => {
            const row = rowData as ExchangesOverviewDataType;
            return (
              <Fragment>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell>
                  <NextLink
                    href={`exchange/${addUnderscore(row.exch_exchange_title)}`}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        trackEvent({
                          action: `Exchange ${row.exch_exchange_title}`,
                          category: GAEventCategory.ExchangesPage,
                          label: `Exchange ${row.exch_exchange_title}`,
                        })
                      }>
                      <Box mr={1} height={24} width={24}>
                        <SkeletonImg
                          width={24}
                          height={24}
                          src={row.exch_logo_url}
                          skeletonProps={{ variant: "circular" }}
                          alt={`${row.exch_exchange_title} logo`}
                        />
                      </Box>
                      <Link
                        variant="body2"
                        color="inherit"
                        href={`exchange/${addUnderscore(
                          row.exch_exchange_title,
                        )}`}>
                        {row.exch_exchange_title}
                      </Link>
                    </Box>
                  </NextLink>
                </TableCell>
                <TableCell align="center">
                  <NumberFormat
                    value={volumeVal(row, currency, interval)}
                    currency={currency.slug}
                    format="bigNumber"
                  />
                </TableCell>
                <TableCell align="center">
                  <NumberDynamic
                    boxProps={{ justifyContent: "center" }}
                    dynamic={Math.sign(
                      volumeChangeVal(row, currency, interval),
                    )}>
                    {format(",.2f")(
                      Math.abs(volumeChangeVal(row, currency, interval)),
                    )}
                    %
                  </NumberDynamic>
                </TableCell>
                <TableCell align="center">{numOfPairsVal(row)}</TableCell>
                <TableCell align="center">
                  {currencify(
                    format(
                      row[
                        `average_transaction_${interval.slug}_${currency.slug}`
                      ] > 0.01
                        ? ",.2f"
                        : ",.3~r",
                    )(
                      row[
                        `average_transaction_${interval.slug}_${currency.slug}`
                      ],
                    ),
                    currency.value,
                  )}
                </TableCell>
                <TableCell align="center">
                  {format(",d")(row[`total_trades_${interval.slug}`])}
                </TableCell>
                <TableCell align="center">
                  <NumberDynamic
                    boxProps={{ justifyContent: "center" }}
                    dynamic={Math.sign(tradeChangeVal(row, interval))}>
                    {format(",.2f")(Math.abs(tradeChangeVal(row, interval)))}%
                  </NumberDynamic>
                </TableCell>
                <TableCell align="center">
                  {askTotVal(row, currency) ? (
                    <NumberFormat
                      value={askTotVal(row, currency)}
                      currency={currency.slug}
                      format="bigNumber"
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      className={mediaClasses.displayOnDesktop}>
                      N/A
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {bidTotVal(row, currency) ? (
                    <NumberFormat
                      value={bidTotVal(row, currency)}
                      currency={currency.slug}
                      format="bigNumber"
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      className={mediaClasses.displayOnDesktop}>
                      N/A
                    </Typography>
                  )}
                </TableCell>
                {activeCategory <= 1 && (
                  <TableCell
                    align="center"
                    padding="none"
                    className={classes.desktopChart}>
                    <Box className={classes.desktopChartImgWrap}>
                      <SkeletonImg
                        width="100%"
                        height="100%"
                        src={`${
                          process.env.WEBSITE
                        }/chart-img/${getExchangeSlug(
                          row.exch_exchange_title,
                        )}-volume-${interval.slug}-usd.png`}
                        skeletonProps={{ variant: "rectangular" }}
                      />
                    </Box>
                  </TableCell>
                )}
              </Fragment>
            );
          }}
          mobileItem={(rowData, { index, currency, interval }) => {
            const row = rowData as ExchangesOverviewDataType;
            const volumeChart = activeCategory <= 1 && (
              <Box
                display="flex"
                width="100%"
                paddingTop={2}
                className={classes.mobileChart}>
                <InfoBlock
                  title="Volume Chart"
                  value={
                    <div
                      style={{
                        position: "relative",
                        left: -16,
                      }}>
                      <SkeletonImg
                        width="100%"
                        height={64}
                        src={`${
                          process.env.WEBSITE
                        }/chart-img/${getExchangeSlug(
                          row.exch_exchange_title,
                        )}-volume-${interval.slug}-usd.m.png`}
                        skeletonProps={{ variant: "rectangular" }}
                      />
                    </div>
                  }
                  display="flex"
                  flexDirection="column"
                  flex="100% 0 0"
                  titleVariant="caption"
                />
              </Box>
            );

            const info = (
              <>
                <Box display="flex" alignItems="center">
                  <Box mr={1} display="flex">
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      fontWeight={600}>
                      #{index + 1}
                    </Typography>
                  </Box>
                  <NextLink
                    href={`exchange/${addUnderscore(row.exch_exchange_title)}`}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        trackEvent(
                          {
                            action: `Exchange ${row.exch_exchange_title}`,
                            category: GAEventCategory.ExchangesPage,
                            label: `Exchange ${row.exch_exchange_title}`,
                          },
                          isMobile,
                        )
                      }>
                      <Box mr={1} height={24} width={24}>
                        <SkeletonImg
                          width={24}
                          height={24}
                          src={row.exch_logo_url}
                          skeletonProps={{ variant: "circular" }}
                          alt={`${row.exch_exchange_title} logo`}
                        />
                      </Box>
                      <Link
                        href={`exchange/${addUnderscore(
                          row.exch_exchange_title,
                        )}`}
                        color="inherit">
                        <Typography variant="body1">
                          {row.exch_exchange_title}
                        </Typography>
                      </Link>
                    </Box>
                  </NextLink>
                </Box>
                <Box display="flex" mt={2} mb={2}>
                  <InfoBlock
                    title="Volume"
                    value={
                      <Typography variant="body2">
                        <NumberFormat
                          value={volumeVal(row, currency, interval)}
                          currency={currency.slug}
                          format=",d"
                          shrinkForMobile={false}
                        />
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Volume Change"
                    value={
                      <NumberDynamic
                        dynamic={Math.sign(
                          volumeChangeVal(row, currency, interval),
                        )}
                        typographyProps={{ variant: "body2" }}>
                        {format(",.2f")(
                          Math.abs(volumeChangeVal(row, currency, interval)),
                        )}
                        %
                      </NumberDynamic>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                </Box>
              </>
            );

            return (
              <>
                {info}
                <Box display="flex">
                  <InfoBlock
                    title="No. of Pairs"
                    value={
                      <Typography variant="body2">
                        {numOfPairsVal(row)}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Avg. Trade"
                    value={
                      <Typography variant="body2">
                        {currencify(
                          format(
                            row[
                              `average_transaction_${interval.slug}_${currency.slug}`
                            ] > 0.01
                              ? ",.2f"
                              : ",.3~r",
                          )(
                            row[
                              `average_transaction_${interval.slug}_${currency.slug}`
                            ],
                          ),
                          currency.value,
                        )}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                </Box>
                <Box display="flex" mt={2}>
                  <InfoBlock
                    title="No. of Trades"
                    value={
                      <Typography variant="body2">
                        {format(",d")(row[`total_trades_${interval.slug}`])}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="No. of Trades Change"
                    value={
                      <NumberDynamic
                        typographyProps={{ variant: "body2" }}
                        dynamic={Math.sign(tradeChangeVal(row, interval))}>
                        {format(",.2f")(
                          Math.abs(tradeChangeVal(row, interval)),
                        )}
                        %
                      </NumberDynamic>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                </Box>
                <Box display="flex" mt={2} mb={2}>
                  <InfoBlock
                    title="Asks Orderbook Depth"
                    value={
                      <Typography variant="body2">
                        {askTotVal(row, currency) ? (
                          <NumberFormat
                            value={askTotVal(row, currency)}
                            currency={currency.slug}
                            format="bigNumber"
                          />
                        ) : (
                          "N/A"
                        )}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Bids Orderbook Depth"
                    value={
                      <Typography variant="body2">
                        {bidTotVal(row, currency) ? (
                          <NumberFormat
                            value={bidTotVal(row, currency)}
                            currency={currency.slug}
                            format=",d"
                            shrinkForMobile={false}
                          />
                        ) : (
                          "N/A"
                        )}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="50% 0 0"
                    titleVariant="caption"
                  />
                </Box>
                {volumeChart}
              </>
            );
          }}
          gaEventCategory={GAEventCategory.ExchangesPage}
        />
      </Box>
    </>
  );
}

export const getStaticProps = withAppProps(async () => {
  const data = await getOverview();
  const trends = await getTopTrends();

  return {
    props: {
      initialData: data,
      initialTrends: trends,
    },
    revalidate: 60 * 5,
  };
});
