import { ITheme } from "@lib/themes/types";
import { makeStyles } from "@lib/themes";
import { hideScrollBar } from "@lib/utils/cssUtils";

const useMediaStyles = makeStyles((theme: ITheme) => ({
  displayOnMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  displayOnDesktop: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
}));

const useAuthPageStyles = makeStyles((theme: ITheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    position: "relative",
    color: theme.palette.text.primary,
    zIndex: 1,
    marginTop: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4.5),
    },
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(10),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(10),
      position: "absolute",
      top: 0,
      right: 0,
      height: "100vh",
      minWidth: 800,
      zIndex: 2,
    },
  },
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: 640,
    zIndex: 1,
    background: "transparent",
    overflow: "auto",
    ...hideScrollBar,

    [theme.breakpoints.up("md")]: {
      padding: "80px 80px 40px 80px",
      background: theme.palette.table.background,
      border: `1px solid ${theme.palette.table.border}`,
      borderRadius: 16,
      height: "100%",
      position: "relative",
    },

    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
    },
  },
  navItem: {
    color: theme.palette.primary.main,
    marginLeft: 4,
  },
  form: {
    width: "100%",
  },
  formResult: {
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(4.5),
    },
  },
  formElement: {
    width: "100%",
    paddingBottom: theme.spacing(3),

    // [theme.breakpoints.up("md")]: {
    //   paddingBottom: theme.spacing(5),
    // },
    "& input:-webkit-autofill": {
      borderRadius: "0px !important",
    },
  },
  note: {
    display: "flex",
    // paddingBottom: theme.spacing(3),

    // [theme.breakpoints.up("md")]: {
    //   paddingBottom: theme.spacing(5),
    // },
  },
  continue: {
    width: 240,
    marginTop: theme.spacing(3),
  },
  textLeft: {
    width: "100%",
    textAlign: "left",
  },
  button: {
    background: "linear-gradient(273.74deg, #03748A 3.07%, #63B3C2 96.93%)",
    boxShadow: "0px 4px 40px rgba(255, 255, 255, 0.04)",
    borderRadius: 8,
    textTransform: "capitalize",
    padding: "12px 90px",
    "&.Mui-disabled": {
      opacity: "0.3 !important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
      width: "100%",
    },
  },
  flotLeft: {
    [theme.breakpoints.up("md")]: {
      left: "0 !important",
      right: "unset",
    },
  },
  topLinkTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "100%",
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginBottom: 24,
    },
  },
  submitContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  copyright: {
    display: "flex",
    flex: "1 0 auto",
    justifyContent: "flex-end",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  backButton: {
    display: "flex",
    marginBottom: 40,
    cursor: "pointer",
    "& svg": {
      "& path": {
        fill: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: 16,
      left: 24,
    },
  },
  mainButton: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 8,
    },
  },
}));

export { useMediaStyles, useAuthPageStyles };
