import React from "react";

import { Box } from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import { overviewCategories } from "@lib/utils";

interface SelectCategoryProps {
  categories?: string[];
  activeCategory: number;
  onChangeCategory: (index) => void;
}

const useStyles = makeStyles((theme: ITheme) => ({
  category: {
    padding: "4px 12px",
    display: "flex",
    borderRadius: 6,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.picker.backgroundHover,
    },
    marginRight: 8,
    "&:last-child": {
      marginRight: 0,
    },
    color: theme.palette.picker.text,
  },
  activeCategory: {
    background: `${theme.palette.picker.background} !important`,
    border: `1px solid ${theme.palette.picker.border} !important`,
    padding: "3px 11px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    "&:hover": {
      background: `${theme.palette.picker.backgroundSelectedHover} !important`,
    },
    color: "#fff !important",
  },
}));

const SelectCategory = ({
  categories = overviewCategories,
  activeCategory,
  onChangeCategory,
}: SelectCategoryProps) => {
  const classes = useStyles();
  return (
    <Box display="flex" mb={2}>
      {categories.map((category, index) => (
        <Box
          onClick={() => onChangeCategory(index)}
          className={`${classes.category} ${
            activeCategory === index ? classes.activeCategory : ""
          }`}>
          {category}
        </Box>
      ))}
    </Box>
  );
};

export default SelectCategory;
